.home-section {
    height: 100vh;
    background-image: url('../img/background.png');
    background-size: cover;
    background-position: center;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 2rem;
}

.home-title {
    font-size: 4rem;
    font-weight: bold;
    margin-bottom: 1rem;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.home-subtitle {
    font-size: 1.5rem;
    margin-bottom: 2rem;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.cta-buttons {
    display: flex;
    gap: 1rem;
    margin-bottom: 2rem;
}

.cta-button {
    background-color: #f5c07a;
    color: white;
    padding: 0.75rem 1.5rem;
    font-size: 1.25rem;
    border-radius: 0.5rem;
    text-decoration: none;
    transition: background-color 0.3s;
}

.cta-button:hover {
    background-color: #d89b58;
}

.discord-button {
    background-color: #7289da;
}

.discord-button:hover {
    background-color: #5b6eae;
}

.server-ip-button {
    font-size: 1.25rem;
    margin-top: 1rem;
    background: rgba(0, 0, 0, 0.5);
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    border: none;
    cursor: pointer;
    color: white;
    transition: background-color 0.3s;
}

.server-ip-button:hover {
    background: rgba(0, 0, 0, 0.7);
}
