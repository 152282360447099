.about-section {
    padding: 6rem 1rem;
    background-color: #333;
    color: white;
    text-align: center;
}

.about-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
    gap: 2rem;
}

@media (min-width: 768px) {
    .about-container {
        flex-direction: row;
        justify-content: space-between;
    }
}

.about-content, .contact-form {
    flex: 1;
}

.about-title, .contact-title {
    font-size: 2rem;
    margin-bottom: 1rem;
}

.about-text {
    font-size: 1.25rem;
    margin-bottom: 2rem;
}

.about-links {
    display: flex;
    justify-content: center;
    gap: 1.5rem;
}

.about-link {
    color: #f5c07a;
    text-decoration: none;
}

.about-link:hover {
    text-decoration: underline;
}

.form {
    max-width: 400px;
    margin: 0 auto;
}

.form-group {
    margin-bottom: 1.5rem;
}

.form-label {
    display: block;
    font-size: 1.125rem;
    margin-bottom: 0.5rem;
}

.form-input, .form-textarea {
    width: 100%;
    padding: 0.75rem;
    border: 1px solid #ccc;
    border-radius: 0.5rem;
    background-color: #fff;
    color: #333; /* Textfarbe */
}

.form-input:focus, .form-textarea:focus {
    border-color: #f5c07a;
    outline: none;
}

.form-button {
    background-color: #f5c07a;
    color: white;
    font-size: 1.125rem;
    padding: 0.75rem 1.5rem;
    border: none;
    border-radius: 0.5rem;
    cursor: pointer;
}

.form-button:hover {
    background-color: #d89b58;
}

.success-message {
    margin-top: 1rem;
    color: green;
    font-weight: bold;
}

.footer-text {
    margin-top: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
}

.footer-link {
    color: #f5c07a;
    text-decoration: none;
}

.footer-link:hover {
    text-decoration: underline;
}
