.roadmap-section {
    padding: 6rem 1rem;
    background-color: #f9f9f9;
}

.timeline {
    position: relative;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0;
    list-style: none;
}

.timeline::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    width: 2px;
    background: #c5c5c5;
    transform: translateX(-50%);
}

.timeline-item {
    display: flex;
    align-items: center;
    margin: 2rem 0;
    position: relative;
}

.timeline-left {
    flex: 1;
    text-align: right;
    padding-right: 1rem;
}

.timeline-center {
    position: relative;
    width: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.timeline-icon {
    width: 24px;
    height: 24px;
    background: #fff;
    border-radius: 50%;
    z-index: 1;
    margin-bottom: 1rem;
}

.timeline-line {
    width: 2px;
    flex-grow: 1;
    background: #c5c5c5;
    z-index: 0;
}

.timeline-content {
    flex: 4;
    display: flex;
    align-items: center;
    background: #fff;
    border-radius: 6px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    padding: 1rem;
    margin-left: 1rem;
    position: relative;
    text-align: left; /* Ensure text is left-aligned */
}

.timeline-image {
    width: 80px;
    height: 80px;
    object-fit: cover;
    border-radius: 50%;
    margin-right: 1rem;
}

.timeline-title {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
}

.timeline-features {
    padding-left: 1rem;
    list-style: disc;
    text-align: left; /* Ensure bullet points are left-aligned */
}

.timeline-feature-item {
    text-align: left; /* Ensure individual bullet points are left-aligned */
}

.timeline-date {
    font-size: 1rem;
    margin-bottom: 1rem;
}

.timeline-item.fade {
    background: linear-gradient(to right, rgba(255, 255, 255, 0), white);
    position: relative;
}
