body {
  scroll-behavior: smooth;
  font-family: 'Arial', sans-serif;
}

.navbar {
  background-color: #333;
  color: #fff;
  padding: 1rem;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
  display: flex;
  justify-content: center;
}

.navbar ul {
  list-style: none;
  display: flex;
  gap: 1rem;
}

.navbar a {
  color: #fff;
  text-decoration: none;
}

.navbar a:hover {
  color: #f5c07a;
}

.section {
  padding: 6rem 1rem;
  max-width: 800px;
  margin: 0 auto;
}

.cta-button {
  background-color: #f5c07a;
  color: white;
  padding: 0.5rem 1rem;
  text-decoration: none;
  border-radius: 5px;
}

.cta-button:hover {
  background-color: #d89b58;
}

footer {
  padding: 6rem 1rem;
  background-color: #333;
  color: #fff;
  text-align: center;
}