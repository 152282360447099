.impressum-page {
    padding-top: 6rem;
}

.impressum-content {
    padding: 6rem 1rem;
    background-color: #f9f9f9;
    color: #333;
}

.impressum-content p {
    margin-bottom: 1.5rem;
}

.impressum-content strong {
    font-weight: bold;
}

.navbar ul {
    list-style: none;
    display: flex;
    gap: 1rem;
}

.navbar a {
    color: #fff;
    text-decoration: none;
}

.navbar a:hover {
    color: #f5c07a;
}
